import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";

import { store } from "./redux";

import { AdminLayout } from "./layouts/AdminLayout";
import { AppLayout } from "./layouts/AppLayout";
import { ForgotPasswordPage } from "./pages/ForgotPassword";
import { IndexPage } from "./pages/IndexPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { ProfilePage } from "./pages/ProfilePage";
import { SignInPage } from "./pages/SignInPage";
import { SignOutPage } from "./pages/SignOutPage";
import { SignUpPage } from "./pages/SignUpPage";
import { RedirectPage } from "./pages/RedirectPage";
import { AdminUsersPage } from "./pages/admin/AdminUsersPage";
import { AuthenticatedLayout } from "./layouts/AuthenticatedLayout";
import { AdminPublicVoicesPage } from "./pages/admin/AdminPublicVoicesPage";

export const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<AppLayout />}
          >
            <Route
              path="/signin"
              element={<SignInPage />}
            />
            <Route
              path="/signup"
              element={<SignUpPage />}
            />
            <Route
              path="/forgot-password"
              element={<ForgotPasswordPage />}
            />
            <Route
              path="/"
              element={<AuthenticatedLayout />}
            >
              <Route
                path="/"
                element={<IndexPage />}
              />
              <Route
                path="/profile"
                element={<ProfilePage />}
              />
              <Route
                path="/signout"
                element={<SignOutPage />}
              />
            </Route>
            <Route
              path="/admin"
              element={<AdminLayout />}
            >
              <Route
                path="/admin"
                element={<RedirectPage path="/admin/users" />}
              />
              <Route
                path="/admin/users"
                element={<AdminUsersPage />}
              />
              <Route
                path="/admin/publicVoices"
                element={<AdminPublicVoicesPage />}
              />
            </Route>

            <Route
              path="*"
              element={<NotFoundPage />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};
