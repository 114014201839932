import { useState, useEffect } from "react";
import clsx from "clsx";

import { Button } from "../components/base/Button";
import { Generation, generationActions } from "../redux/reducers/generationSlice";
import { Icon } from "../components/base/Icon";
import { InputField } from "../components/base/InputField";
import { publicVoiceActions } from "../redux/reducers/publicVoiceSlice";
import { Text } from "../components/base/Text";
import { useAppDispatch, useAppSelector } from "../redux";
import { View } from "../components/base/View";
import { voiceActions } from "../redux/reducers/voiceSlice";
import { SearchField } from "../components/base/SearchField";
import { FieldLabel } from "../components/base/FieldLabel";
import { Sidebar } from "../components/Sidebar";
import { AddVoiceDialog } from "../components/AddVoiceDialog";
import { Switch } from "../components/base/Switch";
import { AudioPlayer } from "../components/AudioPlayer";
import { fileActions } from "../redux/reducers/fileSlice";

export const IndexPage = () => {
  const dispatch = useAppDispatch();
  const isLoadingGeneration = useAppSelector(state => state.generation.isLoadingCreate);
  const voices = Object.values(useAppSelector(state => state.voice.entities));
  const publicVoices = Object.values(useAppSelector(state => state.publicVoice.entities));
  const combinedVoices = [...voices, ...publicVoices].sort((a, b) => a.name.localeCompare(b.name));
  const [generationPreview, setGenerationPreview] = useState<Generation | null>(null);
  const [formData, setFormData] = useState<Partial<Generation>>({ quality: "medium" });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showAddVoiceDialog, setShowAddVoiceDialog] = useState(false);

  const validateForm = () => {
    if (formData.quality === "voice") {
      return formData.voiceId && formData.sourceUrl;
    }

    if (formData.quality === "high") {
      return formData.voiceId && formData.text && formData.notes;
    }

    return formData.voiceId && formData.text;
  };

  const onClickAddVoice = () => {
    setShowAddVoiceDialog(true);
  };

  const onClickSubmit = async () => {
    setGenerationPreview(null);

    const response = await dispatch(generationActions.create(formData)).unwrap();

    if (response) {
      setGenerationPreview(response);
    }
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);

      const response = await dispatch(fileActions.uploadFile({ type: "audio", file: e.target.files[0] })).unwrap();

      setFormData({ ...formData, sourceUrl: response });
    }
  };

  const onCloseVoiceDialog = (newVoiceId?: string) => {
    setShowAddVoiceDialog(false);

    if (newVoiceId) {
      setFormData({ ...formData, voiceId: newVoiceId });
    }
  };

  useEffect(() => {
    dispatch(publicVoiceActions.get({}));
    dispatch(voiceActions.get({}));
  }, []);

  return (
    <View className="w-full h-full flex flex-col md:flex-row">
      <Sidebar 
        onChange={(value) => setFormData({ ...formData, quality: value })}
        activeMode={formData.quality}
        className="hidden md:flex"
      />
      <View className="flex-1 h-full p-8 pt-16 flex flex-col">
        <Switch 
          className="flex md:hidden mb-4"
          value={formData.quality}
          onChange={(value) => setFormData({ ...formData, quality: value })}
          options={[
            { label: "Turbo", value: "low" },
            { label: "HD", value: "medium" },
            { label: "Studio", value: "high" },
            { label: "Voice", value: "voice" },
          ]}
        />
        <View className="h-full flex flex-col gap-4 bg-slate-800 bg-opacity-50 backdrop-blur-lg rounded-xl p-6 border border-purple-500/30">
          <SearchField
            name="voiceId"
            label="Select Voice"
            options={combinedVoices.map(v => ({ value: v.id, label: v.name, previewUrl: v.previewUrl })) || []}
            placeholder="Choose a voice"
            className="bg-white bg-opacity-90"
            onClickAddOption={onClickAddVoice}
            onChange={(value) => setFormData({ ...formData, voiceId: value })}
            value={formData.voiceId}
            showAddOption
          />
          {formData.quality === "voice" ? (
            <FieldLabel 
              label="Upload Audio File"
              className="flex-grow"
            >
              <View className="w-full h-48 border border-dashed border-purple-500/30 rounded-lg flex items-center justify-center bg-slate-800 bg-opacity-50 backdrop-blur-lg flex-grow">
                <input
                  type="file"
                  accept="audio/*"
                  onChange={onFileChange}
                  className="hidden"
                  id="audio-upload"
                />
                <label
                  htmlFor="audio-upload"
                  className="cursor-pointer flex flex-col items-center"
                >
                  <Icon name="upload" size={48} className="text-purple-400 mb-4" />
                  <Text className="text-purple-400 text-lg">
                    {selectedFile ? selectedFile.name : "Click to upload audio file"}
                  </Text>
                </label>
              </View>
            </FieldLabel>
          ) : (
            <>
              <InputField
                name="text"
                label="Your Text"
                placeholder="Enter the text you want to convert to speech..."
                type="textarea"
                className="bg-white bg-opacity-90 flex-1 flex-grow"
                labelClassName="flex-grow min-h-[180px]"
                onChange={(value) => setFormData({ ...formData, text: value })}
                value={formData.text}
              />
              {formData.quality === "high" && (
                <InputField
                  name="notes"
                  label="Director's Notes"
                  placeholder="Enter any additional notes how the text should be read..."
                  type="textarea"
                  className="bg-white bg-opacity-90 flex-1 flex-grow"
                  labelClassName="flex-grow min-h-[180px]"
                  onChange={(value) => setFormData({ ...formData, notes: value })}
                  value={formData.notes}
                />
              )}
              <Text className="text-gray-400 text-sm text-right">
                Characters Count: {formData.text?.length || 0}
              </Text>
            </>
          )}
        </View>

        <View className="mt-8 flex items-center justify-center">
          <View className="w-full max-w-4xl flex flex-col-reverse md:flex-row items-center justify-center gap-4">
            <AudioPlayer 
              audioUrl={generationPreview?.audioUrl}
            />
            <Button 
              onClick={onClickSubmit}
              isDisabled={!validateForm()}
              className={clsx(
                "bg-purple-600 text-white hover:bg-purple-700 text-lg md:text-xl px-4 md:px-8 py-2 md:py-3 disabled:opacity-50",
                "transition-all duration-300",
                generationPreview ? "w-full md:w-1/3 md:translate-x-0" : "w-full md:w-1/2 md:-translate-x-2/3"
              )}
              isLoading={isLoadingGeneration}
            >
              <Text className="text-base whitespace-nowrap">{formData.quality === "voice" ? "Change Voice" : "Convert to Speech"}</Text>
            </Button>
          </View>
        </View>
      </View>

      <AddVoiceDialog
        isOpen={showAddVoiceDialog}
        onClose={onCloseVoiceDialog}
      />
    </View>
  );
};
