import clsx from "clsx";
import { useState, useEffect, useRef } from "react";

import { FieldLabel } from "./FieldLabel";
import { Style } from "../../utils/types";
import { View } from "./View";
import { Icon } from "./Icon";
import { Button } from "./Button";
import { Text } from "./Text";

type SearchFieldProps = {
  name?: string;
  options: Array<{ 
    label: string;
    value: string;
    color?: string;
    previewUrl?: string;
  }>;
  value?: string;
  onChange?: (value: string) => void;
  onClickAddOption?: () => void;
  className?: string;
  label?: string;
  placeholder?: string;
  style?: Style;
  showAddOption?: boolean;
};

export const SearchField = ({ showAddOption, className, label, onChange = () => {}, options = [], placeholder, style, value, onClickAddOption }: SearchFieldProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [playingUrl, setPlayingUrl] = useState<string | null>(null);
  const searchFieldRef = useRef<HTMLDivElement>(null);

  const filteredOptions = options
    .map((option, index) => ({
      ...option,
      color: [
        'linear-gradient(45deg, #FF6B6B, #FFE66D)',
        'linear-gradient(45deg, #00F5A0, #00D9F5)',
        'linear-gradient(45deg, #FF61D2, #FE9090)',
        'linear-gradient(45deg, #4158D0, #C850C0)',
        'linear-gradient(45deg, #FFCC70, #C850C0)',
        'linear-gradient(45deg, #43E97B, #38F9D7)',
        'linear-gradient(45deg, #FA8BFF, #2BD2FF)',
        'linear-gradient(45deg, #FF9A8B, #FF6A88)',
      ][index % 6]
    }))
    .filter(opt => opt.label.toLowerCase().includes(search.toLowerCase()))
    .slice(0, 5);

  useEffect(() => {
    const onClickOutside = (event: MouseEvent) => {
      if (searchFieldRef.current && !searchFieldRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', onClickOutside);

    return () => {
      document.removeEventListener('mousedown', onClickOutside);
    };
  }, []);

  const onClickAddOptionInternal = () => {
    setIsOpen(false);
    onClickAddOption?.();
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setIsOpen(true);
  };

  const onClickOptionHandler = (value: string) => {
    onChange(value);
    setIsOpen(false);
    setSearch("");
  };

  const onClickPreviewHandler = (url?: string, e?: React.MouseEvent) => {
    e?.stopPropagation();
    if (!url) return;
    
    if (audio && playingUrl === url) {
      audio.pause();
      setAudio(null);
      setPlayingUrl(null);
      return;
    }
    
    if (audio) {
      audio.pause();
    }
    
    const newAudio = new Audio(url);
    newAudio.play();
    newAudio.addEventListener('ended', () => {
      setPlayingUrl(null);
      setAudio(null);
    });
    setAudio(newAudio);
    setPlayingUrl(url);
  };

  const selectedOption = options.find(opt => opt.value === value);

  return (
    <FieldLabel
      label={label}
      className={label ? "max-h-[86px] overflow-visible z-50" : "max-h-[64px] overflow-visible z-50"}
    >
      <View ref={searchFieldRef} className="relative w-full">
        <input
          value={isOpen ? search : (selectedOption?.label || "")}
          onChange={onChangeInput}
          onFocus={() => setIsOpen(true)}
          placeholder={placeholder}
          style={style}
          className={clsx(
            "relative w-full rounded-lg py-3 px-4 text-base !bg-slate-800 z-50",
            "border border-purple-500/30 text-white placeholder-[#c4c4c4] outline-none",
            "hover:bg-slate-700/50 transition-colors duration-200",
            value ? "font-medium" : "text-[#c4c4c4] font-light",
            isOpen && "rounded-b-none !border-b-0",
            className
          )}
        />
        
        <View className="absolute right-4 top-1/2 -translate-y-1/2 z-50">
          <Icon
            name="search"
            size={24}
            className="text-purple-400"
          />
        </View>

        {isOpen && (
          <View className="absolute z-30 w-full bg-slate-800 border border-purple-500/30 rounded-b-lg">
            {showAddOption && (
              <Button
                type="basic"
                onClick={onClickAddOptionInternal}
                className="w-full flex items-center justify-between px-3 py-2 hover:bg-slate-700/50 cursor-pointer !rounded-none border-b border-purple-500/30"
              >
                <View className="flex items-center">
                  <View 
                    className="w-6 h-6 rounded-full mr-3" 
                    style={{
                      background: 'linear-gradient(45deg, #4D4D4D, #B3B3B3)',
                    }}
                  />
                  <Text className="text-white">Add New Voice</Text>
                </View>
                <View className="flex items-center justify-center !w-8 !h-8 !rounded-full hover:!bg-purple-500/20">
                  <Icon
                    name="add"
                    size={20}
                    className="text-purple-400 hover:text-purple-300"
                  />
                </View>
              </Button>
            )}

            {filteredOptions.length > 0 && filteredOptions.slice(0, 4).map((option, index) => (
              <Button
                key={option.value}
                type="basic"
                onClick={() => onClickOptionHandler(option.value)}
                className={clsx(
                  "w-full flex items-center justify-between px-3 py-2 hover:bg-slate-700/50 cursor-pointer !rounded-none",
                  index !== 0 && "border-t border-purple-500/30"
                )}
              >
                <View className="flex items-center">
                  <View 
                    className="w-6 h-6 rounded-full mr-3" 
                    style={{
                      background: option.color || "#FF6B6B",
                    }}
                  />
                  <Text className="text-white">{option.label}</Text>
                </View>
                {option.previewUrl && (
                  <Button
                    type="basic"
                    className={clsx(
                      "!w-8 !h-8 !rounded-full active:scale-95",
                      playingUrl === option.previewUrl ? "!bg-purple-500/20" : "hover:!bg-purple-500/20"
                    )}
                    onClick={(event) => onClickPreviewHandler(option.previewUrl, event)}
                  >
                    <Icon
                      name={playingUrl === option.previewUrl ? "pause" : "play-arrow"}
                      size={20}
                      className="text-purple-400 hover:text-purple-300"
                    />
                  </Button>
                )}
              </Button>
            ))}
          </View>
        )}
      </View>
    </FieldLabel>
  );
};
