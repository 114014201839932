import { Button } from "./base/Button";
import { Text } from "./base/Text";
import { View } from "./base/View";
import clsx from "clsx";

interface SidebarProps {
  onChange: (mode: string) => void;
  activeMode?: string;
  className?: string;
}

const OPTIONS = [
  {
    id: "low",
    icon: "🚀",
    title: "TURBO",
    subtitle: "$0.035/1K chars",
    details: (
      <>
        <Text className="text-sm">• Rapid voice generation for quick testing and drafts</Text>
        <Text className="text-sm">• Cost-effective for experimenting with ideas</Text>
        <Text className="text-sm">• Simplified clarity suitable for basic content</Text>
      </>
    ),
  },
  {
    id: "medium", 
    icon: "⭐",
    title: "HD",
    subtitle: "$0.05/1K chars",
    details: (
      <>
        <Text className="text-sm">• Realistic voices perfect for podcasts, presentations, and everyday content</Text>
        <Text className="text-sm">• Well-balanced quality and speed</Text>
        <Text className="text-sm">• A professional, yet affordable, sound profile</Text>
      </>
    ),
  },
  {
    id: "high",
    icon: "🎭",
    title: "STUDIO",
    subtitle: "$0.45/1K chars",
    details: (
      <>
        <Text className="text-sm">• Ultra-realistic, fully directable voices that bring stories to life</Text>
        <Text className="text-sm">• Ideal for immersive audiobooks, narratives, and high-quality productions</Text>
        <Text className="text-sm">• Delivers the richest, most engaging listening experience</Text>
      </>
    )
  },
  {
    id: "voice",
    icon: "🎤",
    title: "VOICE CHANGER",
    subtitle: "$0.025/minute",
    details: (
      <>
        <Text className="text-sm">• Upload and transform existing audio files into different voices</Text>
        <Text className="text-sm">• Customize voices to match specific characters or styles</Text>
        <Text className="text-sm">• Ideal for creating unique audio experiences for podcasts, videos, and more</Text>
      </>
    ),
  }
] as const;

export const Sidebar = ({ onChange, activeMode, className }: SidebarProps) => {
  return (
    <View className={clsx("w-72 h-full bg-slate-800 bg-opacity-50 backdrop-blur-lg border-r border-purple-500/30 py-6 flex flex-col", className)}>
      <Text className="text-2xl font-bold text-white ml-4 mb-4">
        Prim Voices
      </Text>
      
      {OPTIONS.map(option => (
        <Button
          key={option.id}
          type="basic"
          onClick={() => onChange(option.id)}
          className={clsx(
            "flex items-center justify-start w-full p-4 !rounded-none transition-all duration-200 text-left shadow-none",
            activeMode === option.id ? "bg-purple-500 text-white" : "text-purple-400 hover:bg-purple-500/20"
          )}
        >
          <View 
            className={clsx(
              "absolute top-3 right-3 w-3 h-3 rounded-full border-2",
              activeMode === option.id 
                ? "border-white bg-white" 
                : "border-purple-400"
            )}
          />
          <View className="flex flex-col gap-2 w-full">
            <View className="flex items-center gap-2">
              <Text className="text-lg">{option.icon}</Text>
              <Text className="font-bold">{option.title}</Text>
            </View>
            <Text className="text-sm text-purple-300">{option.subtitle}</Text>
            {activeMode === option.id && option.details}
          </View>
        </Button>
      ))}
    </View>
  );
};
