import clsx from "clsx";
import React from "react";

import { FieldLabel } from "./FieldLabel";

type CheckboxFieldProps = {
  labelClassName?: string;
  className?: string;
  label?: string;
  name?: string;
  onChange?: (value: boolean) => void;
  value?: boolean;
  readOnly?: boolean;
};

export const CheckboxField = ({
  labelClassName,
  className,
  label,
  name,
  onChange = () => {},
  value,
  readOnly,
}: CheckboxFieldProps) => {
  const onChangeInternal = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <FieldLabel label={label} className={clsx("h-auto", labelClassName)}>
      <input
        type="checkbox"
        className={clsx(
          "w-full rounded p-2 text-gray-900 outline-none shadow-lg",
          readOnly ? "bg-gray-200 border-gray-200" : "bg-white border-gray-300",
          className,
        )}
        onChange={onChangeInternal}
        name={name}
        checked={value}
      />
    </FieldLabel>
  );
};
