import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import clsx from "clsx";

import { Button } from "../components/base/Button";
import { Text } from "../components/base/Text";
import { useAppSelector } from "../redux";
import { View } from "../components/base/View";

const MENU_ITEMS = [
  {
    title: "Users",
    path: "/admin/users",
  },
  {
    title: "Public Voices",
    path: "/admin/publicVoices",
  },
];

type AdminLayoutProps = {
  className?: string;
};

export const AdminLayout = ({ className }: AdminLayoutProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.session.user);

  const onClickMenuItemHandler = (path: string) => () => {
    navigate(path);
  };

  useEffect(() => {
    if (!user) {
      navigate(`/signin?redirect=${pathname}`);
    }

    if (user && !user.isAdmin) {
      navigate("/");
    }
  }, [user]);

  return user ? (
    <View className={clsx(className, "flex flex-row items-center justify-start w-full h-full overflow-hidden bg-white text-gray-900 admin-layout")}>
      <View className="flex flex-col items-center justify-between w-[200px] min-w-[200px] h-[100vh] bg-gray-200">
        <View className="flex flex-col items-center w-full h-[100vh]">
          {MENU_ITEMS.map((item) => (
            <Button
              key={item.title}
              type="basic"
              className={clsx("w-full p-4 !justify-start !rounded-none !shadow-none", pathname === item.path ? "bg-gray-300" : "")}
              onClick={onClickMenuItemHandler(item.path)}
            >
              <Text className="text-left">{item.title}</Text>
            </Button>
          ))}
        </View>
      </View>
      <View className="w-full h-[100vh]">
        <Outlet />
      </View>
    </View>
  ) : (
    <View className={className} />
  );
};
