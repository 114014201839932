import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import clsx from "clsx";

import { Button } from "../components/base/Button";
import { useAppSelector } from "../redux";
import { View } from "../components/base/View";
import { Icon } from "../components/base/Icon";
import { useNavigation } from "../hooks/useNavigation";

type AuthenticatedLayoutProps = {
  className?: string;
};

export const AuthenticatedLayout = ({ className }: AuthenticatedLayoutProps) => {
  const navigation = useNavigation();
  const user = useAppSelector((state) => state.session.user);
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const onClickCreate = () => {
    navigation.navigate("/");
  };

  const onClickProfile = () => {
    navigation.navigate("/profile");
  };

  useEffect(() => {
    if (!user) {
      const params = new URLSearchParams();

      searchParams.forEach((value, key) => {
        params.append(key, value);
      });

      navigation.navigate(`/signin?redirect=${pathname}?${params.toString()}`);
    }
  }, [user]);

  return user ? (
    <View className={clsx("relative flex flex-col items-center justify-start w-full h-full overflow-scroll", className)}>
      {pathname === "/profile" ? (
        <Button
          type="basic"
          onClick={onClickCreate}
          className="!absolute top-4 right-4 !w-8 !h-8 !shadow-none !rounded-full border-2 border-white overflow-hidden"
        >
          <Icon name="create" size={24} className="text-white" />
        </Button>
      ) : (
        <Button
          type="basic"
          onClick={onClickProfile}
          className="!absolute top-4 right-4 !w-8 !h-8 !shadow-none !rounded-full border-2 border-white overflow-hidden"
        >
          <Icon name="person" size={32} className="text-white mt-2" />
        </Button>
      )}
      <Outlet />
    </View>
  ) : (
    <View className={className} />
  );
};
