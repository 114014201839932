import clsx from "clsx";

import { Icon } from "../base/Icon";
import { Style } from "../../utils/types";
import { Text } from "../base/Text";
import { View } from "../base/View";

type ErrorMessageProps = {
  className?: string;
  text?: string | null;
  style?: Style;
};

export const ErrorMessage = ({ className, text, style }: ErrorMessageProps) => (
  <View 
    className={clsx("w-full flex flex-row items-center justify-start", text ? "opacity-100" : "opacity-0", className)}
    style={style} 
  >
    <Icon 
      name="error-outline" 
      size={20} 
      className="text-white mr-2" 
    />
    <Text className="text-white text-left">{text}</Text>
  </View>
);
