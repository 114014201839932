import clsx from "clsx";

import { View } from "./View";
import { Text } from "./Text";
import { Style } from "../../utils/types";

type FieldLabelProps = {
  className?: string;
  children: React.ReactNode;
  label?: string;
  style?: Style;
};

export const FieldLabel = ({ className, children, label, style }: FieldLabelProps) => {
  return label ? (
    <View
      className={clsx("w-full flex flex-col items-start justify-start relative", className)}
      style={style}
    >
      <label className="mb-1 text-xl text-gray-300">{label}</label>
      {children}
    </View>
  ) : (
    <View
      className={clsx("relative", className)}
      style={style}
    >
      {children}
    </View>
  );
};
