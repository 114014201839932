import { Outlet } from "react-router-dom";

import { sessionActions } from "../redux/reducers/sessionSlice";
import { useAppDispatch, useAppSelector } from "../redux";
import { useEffect } from "react";
import { View } from "../components/base/View";

export const AppLayout = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.session.isLoadingSession);

  useEffect(() => {
    dispatch(sessionActions.getSession());
  }, []);

  return isLoading ? (
    <View className="flex items-center justify-center w-full h-full">
      <View className="w-16 h-16 border-t-2 border-b-2 border-white rounded-full animate-spin" />
    </View>
  ) : (
    <View className="w-full h-full bg-gradient-to-br from-slate-900 via-purple-900 to-slate-900 overflow-auto">
      <Outlet />
    </View>
  );
};
