export const parseJson = <T>(data: string): T | null => {
  let parsedJson = null;

  try {
    parsedJson = JSON.parse(data) as T;
  } catch (error) {
    // do nothing
  }

  return parsedJson;
};

export const parseState = <T>(state: string): T | null => {
  return parseJson<T>(atob(state));
};

export const encodeState = (state: Record<string, string>): string => {
  return btoa(JSON.stringify(state));
};
