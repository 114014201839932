import { useEffect, useState } from "react";

import { Button } from "../components/base/Button";
import { Form } from "../components/base/Form";
import { InputField } from "../components/base/InputField";
import { sessionActions } from "../redux/reducers/sessionSlice";
import { Text } from "../components/base/Text";
import { useAppDispatch, useAppSelector } from "../redux";
import { useNavigation } from "../hooks/useNavigation";
import { View } from "../components/base/View";

type FormData = {
  email?: string;
  password?: string;
};

export const SignInPage = () => {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.session.isLoading);
  const error = useAppSelector((state) => state.session.error);
  const user = useAppSelector((state) => state.session.user);
  const [formData, setFormData] = useState<FormData>({});
  
  const onChangeFormData = (newFormData: FormData) => {
    setFormData(newFormData);
  };

  const onClickSignIn = () => {
    dispatch(sessionActions.signIn(formData));
  };

  useEffect(() => {
    if (user) {
      navigation.navigate("/profile");
    }
  }, [user]);

  return (
    <View className="w-full h-full">
      <View className="w-full max-w-4xl mx-auto p-8 flex flex-col items-center justify-center min-h-screen">
        <Text className="text-6xl font-bold text-white text-center mb-6">
          Welcome to Prim Voices
        </Text>
        <Text className="text-2xl text-gray-300 text-center mb-8 max-w-2xl">
          Sign in to start creating your own audio today!
        </Text>

        <View className="w-full max-w-md bg-slate-800 bg-opacity-50 backdrop-blur-lg rounded-xl p-6 shadow-xl mb-8 border border-purple-500/30">
          <Form
            data={formData}
            onChange={onChangeFormData}
          >
            <InputField
              className="w-full mb-4 bg-slate-700 bg-opacity-50 text-white placeholder-gray-100 border-purple-500/30"
              name="email"
              placeholder="Email"
              showError={!!error}
            />
            <InputField
              className="w-full mb-4 bg-slate-700 bg-opacity-50 text-white placeholder-gray-100 border-purple-500/30"
              name="password"
              placeholder="Password"
              type="password"
              showError={!!error}
            />
          </Form>
          <Button
            className="w-full mb-4 bg-purple-600 text-white hover:bg-purple-700 border border-purple-400/30"
            isLoading={isLoading}
            onClick={onClickSignIn}
          >
            Sign In
          </Button>
          <Button
            className="w-full mb-4 h-[52px] border border-purple-500/30"
            color="white"
            onClick={() => navigation.navigate("/signup")}
          >
            Create Account
          </Button>
          <Button
            className="w-full text-purple-300 hover:text-purple-200 !shadow-none"
            type="basic"
            onClick={() => navigation.navigate("/forgot-password")}
          >
            Forgot Password
          </Button>
        </View>
      </View>
    </View>
  );
};
