import { useEffect } from "react";

import { View } from "../components/base/View";
import { Text } from "../components/base/Text";
import { sessionActions } from "../redux/reducers/sessionSlice";
import { useAppDispatch } from "../redux";

type SignOutPageProps = {
  className?: string;
};

export const SignOutPage = ({ className }: SignOutPageProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(sessionActions.signOut());
  }, []);

  return (
    <View className="w-full h-full">
      <View className="w-full max-w-4xl mx-auto p-4 flex flex-col items-center justify-center min-h-screen">
        <Text className="text-2xl text-gray-300">Signing out...</Text>
      </View>
    </View>
  );
};
