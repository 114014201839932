import clsx from "clsx";
import { ReactNode, useEffect } from "react";

import { Icon } from "./Icon";
import { View } from "./View";
import { Button } from "./Button";

type DialogProps = {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: ReactNode;
  className?: string;
};

export const Dialog = ({ className, isOpen, onClose, title, children }: DialogProps) => {
  // Prevent scrolling when dialog is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <>
      <div
        className="fixed inset-0 bg-black/60 z-40"
        onClick={onClose}
        aria-hidden="true"
      />
      
      <View className="fixed inset-0 flex items-center justify-center p-4 z-50 !overflow-scroll">
        <View className={clsx("relative w-full max-w-md rounded-lg bg-slate-800 border border-purple-500/30", className)}>
          {title && (
            <>
              <View className="text-lg font-bold text-white p-4 border-b border-purple-500/30">
                {title}
              </View>
              <Button
                onClick={onClose}
                className="!absolute right-4 top-4 text-white/50 hover:text-white w-7 h-7 !shadow-none !w-auto"
                aria-label="Close dialog"
                type="basic"
              >
                <Icon name="close" size={24} />
              </Button>
            </>
          )}
          {children}
        </View>
      </View>
    </>
  );
};

