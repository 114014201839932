import { useEffect, useRef, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";

import { ApiKey } from "../redux/reducers/userSlice";
import { Button } from "../components/base/Button";
import { Dialog } from "../components/base/Dialog";
import { Icon } from "../components/base/Icon";
import { InputField } from "../components/base/InputField";
import { Text } from "../components/base/Text";
import { useApiRequest } from "../hooks/useApiRequest";
import { useAppDispatch, useAppSelector } from "../redux";
import { useNavigation } from "../hooks/useNavigation";
import { View } from "../components/base/View";
import { voiceActions } from "../redux/reducers/voiceSlice";
import { generationActions } from "../redux/reducers/generationSlice";
import { AddVoiceDialog } from "../components/AddVoiceDialog";

const stripePromise = loadStripe("pk_test_51QPUkmHI5hVmfN3zluRCI2YVjwHQPHiS6qbOOL4BImAUGBmeTMEAEWq2B5OgH46t6krGccSR5wGpsfvIaCSZAf3Q007c0km3qj");

export const ProfilePage = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const user = useAppSelector((state) => state.session.user);
  const [showDescriptionDialog, setShowDescriptionDialog] = useState(false);
  const [description, setDescription] = useState("");
  const [showApiKeyDialog, setShowApiKeyDialog] = useState(false);
  const [showPurchaseDialog, setShowPurchaseDialog] = useState(false);
  const [newApiKey, setNewApiKey] = useState("");
  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const [amount, setAmount] = useState(10);
  const [showAddVoiceDialog, setShowAddVoiceDialog] = useState(false);
  const createApiKeyRequest = useApiRequest<{ data: ApiKey }>();
  const getApiKeysRequest = useApiRequest<{ data: ApiKey[] }>();
  const revokeApiKeyRequest = useApiRequest();
  const createPaymentRequest = useApiRequest<{ sessionId: string }>();
  const [isPlayingVoiceId, setIsPlayingVoiceId] = useState("");
  const [isPlayingGenerationId, setIsPlayingGenerationId] = useState("");
  const currentPlayingVoice = useRef<HTMLAudioElement>();
  const currentPlayingGeneration = useRef<HTMLAudioElement>();
  const generations = Object.values(useAppSelector(state => state.generation.entities));
  const voices = Object.values(useAppSelector(state => state.voice.entities));

  // Calculate volume discount based on amount
  const calculateDiscount = (amount: number) => {
    if (amount >= 100) return 0.15; // 15% off for $100+
    if (amount >= 50) return 0.10; // 10% off for $50+

    return 0; // No discount
  };

  const onClickPurchaseAmount = async () => {
    try {
      const stripe = await stripePromise;
      if (!stripe) throw new Error('Stripe failed to load');

      // Create payment session on backend
      const response = await createPaymentRequest.fetch('/v1/payments/create-session', {
        method: 'POST',
        body: {
          amount,
        },
      });

      // Redirect to Stripe checkout
      const result = await stripe.redirectToCheckout({
        sessionId: response.data?.sessionId || ""
      });

      if (result.error) {
        console.error(result.error);
      }
    } catch (err) {
      console.error('Payment error:', err);
    }
  };

  const onClickCreateApiKey = async () => {
    const response = await createApiKeyRequest.fetch("/v1/users/apiKeys", {
      method: "POST",
      body: { 
        description, 
      },
    });

    if (response?.data) {
      setNewApiKey(response.data?.data?.token || "");
      setShowApiKeyDialog(true);
      setShowDescriptionDialog(false);
      setDescription("");

      getApiKeysRequest.fetch("/v1/users/apiKeys");
    }
  };

  const onClickDeleteApiKey = async (keyId: string) => {
    await revokeApiKeyRequest.fetch(`/v1/users/apiKeys/${keyId}`, {
      method: "DELETE",
    });

    getApiKeysRequest.fetch("/v1/users/apiKeys");
  };

  const onClickCopyApiKey = async () => {
    await navigator.clipboard.writeText(newApiKey);
    setShowCopySuccess(true);
    setTimeout(() => {
      setShowCopySuccess(false);
    }, 1500);
  };

  const onClickAddVoiceShowDialog = () => {
    setShowAddVoiceDialog(true);
  };

  const onDeleteVoice = async (voiceId: string) => {
    dispatch(voiceActions.deleteById(voiceId));
  };

  const onClickPlayVoiceHandler = (voiceId: string, voiceSampleUrl: string) => () => {
    if (isPlayingVoiceId === voiceId) {
      currentPlayingVoice.current?.pause();
    } else {
      setIsPlayingVoiceId(voiceId);

      if (currentPlayingVoice.current) {
        currentPlayingVoice.current.pause();
      }
  
      currentPlayingVoice.current = new Audio(voiceSampleUrl);
  
      const onEnded = () => {
        setIsPlayingVoiceId("");
      };
  
      currentPlayingVoice.current.play();
      currentPlayingVoice.current.onended = onEnded;
      currentPlayingVoice.current.onpause = onEnded;
      currentPlayingVoice.current.onerror = onEnded;
    }
  };

  const onClickPlayGenerationHandler = (generationId: string, audioUrl: string) => () => {
    if (isPlayingGenerationId === generationId) {
      currentPlayingGeneration.current?.pause();
    } else {
      setIsPlayingGenerationId(generationId);

      if (currentPlayingGeneration.current) {
        currentPlayingGeneration.current.pause();
      }
  
      currentPlayingGeneration.current = new Audio(audioUrl);
  
      const onEnded = () => {
        setIsPlayingGenerationId("");
      };
  
      currentPlayingGeneration.current.play();
      currentPlayingGeneration.current.onended = onEnded;
      currentPlayingGeneration.current.onpause = onEnded;
      currentPlayingGeneration.current.onerror = onEnded;
    }
  };

  const onClickDownloadHandler = (audioUrl: string) => () => {
    if (audioUrl) {
      const link = document.createElement('a');
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      link.download = `audio-${timestamp}.wav`;
      link.href = audioUrl;
      link.click();
      link.remove();
    }
  };

  useEffect(() => {
    getApiKeysRequest.fetch("/v1/users/apiKeys");

    dispatch(generationActions.get({}));
    dispatch(voiceActions.get({}));
  }, []);

  return (
    <View className="w-full h-full">
      <View className="w-full max-w-4xl mx-auto p-8 space-y-8">
        <Text className="text-4xl font-bold text-white">Your Account</Text>
        <View className="bg-slate-800 bg-opacity-50 backdrop-blur-lg rounded-xl p-6 border border-purple-500/30">
          <Text className="text-xl font-bold text-white mb-4">Balance</Text>
          <View className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
            <Text className="text-3xl text-white mb-4 sm:mb-0">${((user?.balance || 0) / 1000000).toFixed(2)} available</Text>
            <Button
              onClick={() => setShowPurchaseDialog(true)}
              className="bg-purple-600 hover:bg-purple-700 text-white px-6 py-2 sm:max-w-[200px]"
            >
              Add Funds
            </Button>
          </View>
        </View>
        <View className="bg-slate-800 bg-opacity-50 backdrop-blur-lg rounded-xl p-6 border border-purple-500/30">
          <View className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4">
            <Text className="text-xl font-bold text-white mb-4 sm:mb-0">API Keys</Text>
            <Button
              onClick={() => setShowDescriptionDialog(true)}
              className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 sm:max-w-[200px]"
            >
              + Add New Key
            </Button>
          </View>
          {!getApiKeysRequest.data?.data.length ? (
            <Text className="text-white opacity-70">No API keys yet</Text>
          ) : (
            <View className="grid grid-cols-1 gap-4">
              {getApiKeysRequest.data?.data.map((key) => (
                <View key={key.id} className="bg-slate-700 p-4 rounded-lg flex justify-between items-center">
                  <View className="flex flex-col">
                    <Text className="text-white font-bold">{key.description || 'Unnamed Key'}</Text>
                    <Text className="text-white opacity-70 text-xs">
                      {key.lastUsedAt && `Last used ${new Date(key.lastUsedAt).toLocaleDateString()} • `}
                      Created {new Date(key.createdAt).toLocaleDateString()}
                    </Text>
                  </View>
                  <Button
                    onClick={() => onClickDeleteApiKey(key.id)}
                    type="basic"
                    className="!w-8 !h-8 !shadow-none hover:bg-purple-500/20 !rounded-full"
                  >
                    <Icon 
                      name="delete-outline"
                      size={18}
                      className="text-purple-400"
                    />
                  </Button>
                </View>
              ))}
            </View>
          )}
        </View>

        <View className="bg-slate-800 bg-opacity-50 backdrop-blur-lg rounded-xl p-6 border border-purple-500/30">
          <View className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4">
            <Text className="text-xl font-bold text-white mb-4 sm:mb-0">Your Voices</Text>
            <Button
              onClick={onClickAddVoiceShowDialog}
              className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 sm:max-w-[200px]"
            >
              Add Voice
            </Button>
          </View>
          {!voices?.length ? (
            <Text className="text-white opacity-70">No voices yet</Text>
          ) : (
            <View className="grid grid-cols-1 gap-4">
              {voices.map((voice) => (
                <View key={voice.id} className="bg-slate-700 p-4 rounded-lg flex justify-between items-center">
                  <View className="flex flex-col">
                    <Text className="text-white font-bold">{voice.name}</Text>
                  </View>
                  <View className="flex flex-row items-center space-x-2">
                    <Button
                      onClick={onClickPlayVoiceHandler(voice.id, voice.sampleUrl)}
                      type="basic"
                      className="!w-8 !h-8 !shadow-none hover:bg-purple-500/20 !rounded-full"
                    >
                      <Icon
                        name={isPlayingVoiceId === voice.id ? "pause" : "play-arrow"}
                        size={18}
                        className="text-purple-400"
                      />
                    </Button>
                    <Button
                      onClick={() => onDeleteVoice(voice.id)}
                      type="basic"
                      className="!w-8 !h-8 !shadow-none hover:bg-purple-500/20 !rounded-full"
                    >
                      <Icon 
                        name="delete-outline"
                        size={18}
                        className="text-purple-400"
                      />
                    </Button>
                  </View>
                </View>
              ))}
            </View>
          )}
        </View>
        <View className="flex flex-col bg-slate-800 bg-opacity-50 backdrop-blur-lg rounded-xl p-6 border border-purple-500/30">
          <View className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4">
            <Text className="text-xl font-bold text-white mb-4 sm:mb-0">Recent Generations</Text>
            <Button
              onClick={() => navigation.navigate("/create")}
              className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 sm:max-w-[200px]"
            >
              Create New
            </Button>
          </View>
          {!generations.length ? (
            <Text className="text-white opacity-70">No generations yet</Text>
          ) : (
            <View className="grid grid-cols-1 gap-4">
              {generations.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).slice(0, 10).map((generation) => (
                <View key={generation.id} className="bg-slate-700 p-4 rounded-lg flex justify-between items-center">
                  <View className="flex flex-col">
                    <Text className="text-white font-bold">{generation.text}</Text>
                    <Text className="text-white opacity-70 text-xs">
                      Created {new Date(generation.createdAt).toLocaleDateString()}
                    </Text>
                  </View>
                  <View className="flex flex-row items-center space-x-2">
                    <Button
                      onClick={onClickPlayGenerationHandler(generation.id, generation.audioUrl)}
                      type="basic"
                      className="!w-8 !h-8 !shadow-none hover:bg-purple-500/20 !rounded-full"
                    >
                      <Icon
                        name={isPlayingGenerationId === generation.id ? "pause" : "play-arrow"}
                        size={18}
                        className="text-purple-400"
                      />
                    </Button>
                    <Button
                      onClick={onClickDownloadHandler(generation.audioUrl)}
                      type="basic"
                      className="!w-8 !h-8 !shadow-none hover:bg-purple-500/20 !rounded-full"
                    >
                      <Icon
                        name="download"
                        size={18}
                        className="text-purple-400"
                      />
                    </Button>
                  </View>
                </View>
              ))}
            </View>
          )}
        </View>
      </View>

      <AddVoiceDialog
        isOpen={showAddVoiceDialog}
        onClose={() => setShowAddVoiceDialog(false)}
      />

      <Dialog
        isOpen={showDescriptionDialog}
        onClose={() => setShowDescriptionDialog(false)}
        title="New API Key"
      >
        <View className="p-4 space-y-4">
          <Text className="text-white">Enter a description for your new API key:</Text>
          <InputField
            value={description}
            onChange={(description) => setDescription(description)}
            placeholder="Enter key description..."
            className="w-full text-slate-700"
          />
          <Button
            onClick={onClickCreateApiKey}
            isDisabled={!description || createApiKeyRequest.isLoading}
            className="bg-purple-600 hover:bg-purple-700 disabled:bg-purple-400 text-white px-4 py-2 w-full"
          >
            {createApiKeyRequest.isLoading ? "Generating..." : "Generate API Key"}
          </Button>
        </View>
      </Dialog>

      <Dialog
        isOpen={showApiKeyDialog}
        onClose={() => setShowApiKeyDialog(false)}
        title="Your New API Key"
      >
        <View className="flex flex-col p-4">
          <Text className="text-white mb-4">Use this key to authenticate your requests to the API, by adding the key to the Authorization header as a Bearer token.</Text>
          <Text className="text-white mb-4">Please save this API key - you won't be able to see it again!</Text>
          <View className="relative flex flex-row items-center justify-between bg-slate-700 px-2 rounded">
            <Text className="font-mono text-white break-all">{newApiKey}</Text>
            <Button
              onClick={onClickCopyApiKey}
              type="basic"
              className="!w-auto !h-auto !p-2 !shadow-none"
            >
              <Icon 
                name={showCopySuccess ? "checkmark-outline" : "content-copy-outline"}
                size={20} 
                className={showCopySuccess ? "text-green-500" : "text-white"}
              />
            </Button>
          </View>
          <Button
            onClick={() => setShowApiKeyDialog(false)}
            className="mt-4 bg-purple-600 hover:bg-purple-700 text-white px-6 py-2 w-full"
          >
            Close
          </Button>
        </View>
      </Dialog>

      <Dialog
        isOpen={showPurchaseDialog}
        onClose={() => setShowPurchaseDialog(false)}
        title="Add Funds"
      >
        <View className="p-6 space-y-6">
          <Text className="text-white text-lg">Select amount to add:</Text>
          
          <View className="space-y-4">
            <input
              type="range"
              min="10"
              max="200"
              step="10"
              value={amount}
              onChange={(e) => setAmount(parseInt(e.target.value))}
              className="w-full"
            />
            
            <View className="flex justify-between items-center">
              <Text className="text-white text-2xl font-bold">${amount.toFixed(2)}</Text>
              {calculateDiscount(amount) > 0 && (
                <Text className="text-green-500">
                  {(calculateDiscount(amount) * 100)}% discount
                </Text>
              )}
            </View>

            <Text className="text-white opacity-70 text-sm">
              Final amount: ${(amount * (1 - calculateDiscount(amount))).toFixed(2)}
            </Text>
          </View>

          <Button
            onClick={onClickPurchaseAmount}
            className="w-full bg-purple-600 hover:bg-purple-700 text-white px-6 py-3 text-lg"
            isDisabled={createPaymentRequest.isLoading}
          >
            {createPaymentRequest.isLoading ? "Processing..." : "Add Funds"}
          </Button>
        </View>
      </Dialog>
    </View>
  );
};
