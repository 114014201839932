export const base64Encode = (str = "") => {
  return btoa(str);
};

export const capitalize = (str = "") => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const generate = (str: string, count: number, delimiter = "") => {
  return Array(count).fill(str).join(delimiter);
};

export const clean = (str = "") => {
  let formattedStr = str.toLowerCase().replace(/([.,!?:-_\"\'])/g, "");

  formattedStr = formattedStr.replace(/zero/g, "0");
  formattedStr = formattedStr.replace(/one/g, "1");
  formattedStr = formattedStr.replace(/two/g, "2");
  formattedStr = formattedStr.replace(/three/g, "3");
  formattedStr = formattedStr.replace(/four/g, "4");
  formattedStr = formattedStr.replace(/five/g, "5");
  formattedStr = formattedStr.replace(/six/g, "6");
  formattedStr = formattedStr.replace(/seven/g, "7");
  formattedStr = formattedStr.replace(/eight/g, "8");
  formattedStr = formattedStr.replace(/nine/g, "9");

  return formattedStr;
};

export const splitSentences = (str = "") => {
  const sentenceEndings = /([.!?](?:\s|"|'|"|'))/g;
  const markedParagraph = str.replace(sentenceEndings, "$1|");
  const sentences = markedParagraph
    .split("|")
    .map((sentence) => sentence.trim())
    .filter((sentence) => sentence.length > 0);

  return sentences;
};

export const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};
