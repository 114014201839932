import { Dialog } from "./base/Dialog"
import { View } from "./base/View"
import { Text } from "./base/Text"
import { InputField } from "./base/InputField"
import { FileField } from "./base/FileField"
import { Button } from "./base/Button"
import { useState } from "react"
import { useApiRequest } from "../hooks/useApiRequest"
import { useAppDispatch } from "../redux"
import { fileActions } from "../redux/reducers/fileSlice"
import { voiceActions } from "../redux/reducers/voiceSlice"

type AddVoiceDialogProps = {
  isOpen: boolean;
  onClose: (newVoiceName?: string) => void;
};

export const AddVoiceDialog = ({
  isOpen,
  onClose,
}: AddVoiceDialogProps) => {
  const dispatch = useAppDispatch();
  const [newVoiceName, setNewVoiceName] = useState("");
  const [voiceSampleFile, setVoiceSampleFile] = useState<FileList>();
  const [isLoading, setIsLoading] = useState(false);
  
  const onClickAddVoice = async () => {
    if (!newVoiceName || !voiceSampleFile) return;

    setIsLoading(true);

    const fileUrl = await dispatch(fileActions.uploadFile({
      type: "audio",
      file: voiceSampleFile[0],
    })).unwrap();

    const newVoice = await dispatch(voiceActions.create({
      name: newVoiceName,
      sampleUrl: fileUrl,
    })).unwrap();

    setNewVoiceName("");
    setVoiceSampleFile(undefined);
    setIsLoading(false);
    onClose(newVoice.id);
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Add New Voice"
    >
      <View className="p-6 space-y-4">
        <View className="space-y-2">
          <Text className="text-white">Voice Name</Text>
          <InputField
            value={newVoiceName}
            onChange={setNewVoiceName}
            placeholder="Enter voice name..."
            className="w-full"
          />
        </View>

        <View className="space-y-2">
          <Text className="text-white">Voice Sample</Text>
          <FileField
            value={voiceSampleFile}
            onChange={setVoiceSampleFile}
            size="md"
            fileTypes={["wav", "mp3", "m4a", "flac", "ogg"]}
          />
        </View>

        <Button
          onClick={onClickAddVoice}
          className="w-full bg-purple-600 hover:bg-purple-700 text-white px-6 py-3"
          isDisabled={!newVoiceName || !voiceSampleFile}
          isLoading={isLoading}
        >
          Add Voice
        </Button>
      </View>
    </Dialog>
  );
};
