import { CircularProgress } from "@mui/material";
import clsx from "clsx";

import { View } from "./View";
import { forwardRef } from "react";

type ButtonProps = {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseMove?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseUp?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  isLoading?: boolean;
  isDisabled?: boolean;
  color?: "primary" | "secondary" | "danger" | "warning" | "success" | "white";
  type?: "default" | "outlined" | "link" | "text" | "basic";
  href?: string;
  style?: React.CSSProperties;
  iconLeft?: React.ReactNode;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      color = "primary",
      isDisabled,
      isLoading,
      onClick = () => {},
      onMouseDown = () => {},
      onMouseMove = () => {},
      onMouseUp = () => {},
      style,
      type = "default",
      iconLeft,
    },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        className={clsx(
          "relative flex flex-row items-center justify-center w-full cursor-pointer transition-all duration-300 origin-center rounded-xl shadow-lg hover:shadow-xl",
          type !== "basic" && "p-3 text-lg font-extrabold active:scale-95",
          color === "primary" && type === "default" && "text-white bg-gradient-to-r from-primary via-primary-dark to-primary border-none hover:scale-[1.02] hover:brightness-110",
          color === "primary" && type === "outlined" && "text-primary bg-white border-primary border-2 hover:scale-[1.02] hover:bg-primary hover:text-white",
          color === "primary" && type === "link" && "text-primary bg-white hover:scale-[1.02] hover:underline decoration-wavy",
          color === "secondary" && type === "default" && "text-white bg-gradient-to-r from-secondary via-secondary-dark to-secondary border-none hover:scale-[1.02] hover:brightness-110",
          color === "secondary" && type === "outlined" && "text-secondary bg-white border-secondary border-2 hover:scale-[1.02] hover:bg-secondary hover:text-white",
          color === "secondary" && type === "link" && "text-secondary bg-white hover:scale-[1.02] hover:underline decoration-wavy",
          color === "danger" && type === "default" && "text-white bg-gradient-to-r from-red-500 via-red-600 to-red-500 border-none hover:scale-[1.02] hover:brightness-110",
          color === "danger" && type === "outlined" && "text-red-500 bg-white border-red-500 border-2 hover:scale-[1.02] hover:bg-red-500 hover:text-white",
          color === "danger" && type === "link" && "text-red-500 bg-white hover:scale-[1.02] hover:underline decoration-wavy",
          color === "white" && type === "default" && "bg-white bg-opacity-80 border-[rgba(255,255,255,0.4)] hover:border-[#ffffff90] border-2 hover:scale-[1.02] hover:bg-gray-50",
          isDisabled && "opacity-50 cursor-not-allowed hover:scale-100",
          className,
        )}
        style={style}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        disabled={isDisabled}
      >
        {type === "basic" ? (
          children
        ) : (
          <>
            {iconLeft && <span className="mr-2 animate-bounce">{iconLeft}</span>}
            <View className={clsx("flex items-center justify-center select-none", isLoading ? "opacity-0" : "opacity-100")}>{children}</View>
          </>
        )}
        <View className={clsx("absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-6 h-6", isLoading ? "opacity-100" : "opacity-0")}>
          <CircularProgress
            className={clsx(
              "!w-full !h-full animate-spin",
              type === "default" && "!text-white",
              color === "primary" && type === "outlined" && "!text-primary",
              color === "secondary" && type === "outlined" && "!text-secondary", 
              color === "danger" && type === "outlined" && "!text-red-500",
              color === "white" && type === "outlined" && "text-gray-700",
            )}
          />
        </View>
      </button>
    );
  },
);
