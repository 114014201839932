import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";

import { FieldLabel } from "./FieldLabel";
import { Icon } from "./Icon";
import { Button } from "./Button";
import { View } from "./View";

type InputFieldProps = {
  className?: string;
  label?: string;
  name?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  value?: string;
  type?: string;
  readOnly?: boolean;
  autoGrow?: boolean;
  labelClassName?: string;
  showError?: boolean;
};

export const InputField = ({
  className,
  label,
  name,
  onChange = () => {},
  placeholder,
  value,
  type = "text",
  readOnly,
  autoGrow = true,
  labelClassName,
  showError,
}: InputFieldProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [showPassword, setShowPassword] = useState(false);

  const onChangeInternal = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  useEffect(() => {
    if (textareaRef.current && autoGrow) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value, autoGrow]);

  return (
    <FieldLabel
      label={label}
      className={labelClassName}
    >
      {type === "textarea" ? (
        <textarea
          ref={textareaRef}
          className={clsx(
            "w-full rounded-md py-2 px-4 text-xl outline-none !bg-slate-800 border border-purple-500/30 text-white placeholder-gray-400",
            className,
          )}
          onChange={onChangeInternal}
          name={name}
          placeholder={placeholder}
          value={value || ""}
        />
      ) : (
        <input
          className={clsx(
            "w-full rounded-md py-2 px-4 text-xl outline-none !bg-slate-800 border border-purple-500/30 text-white placeholder-gray-400",
            className,
          )}
          type={showPassword ? "text" : type}
          onChange={onChangeInternal}
          name={name}
          placeholder={placeholder}
          value={value || ""}
          disabled={readOnly}
        />
      )}
      <View className="absolute right-3 top-3 flex flex-row items-center justify-center gap-2">
        {type === "password" && (
          <Button
            type="basic"
            className="!w-6 !h-6"
            onClick={() => setShowPassword(!showPassword)}
          >
            <Icon
              name={showPassword ? "eye-outline" : "eye-slash-outline"}
              size={24}
              className="text-gray-500"
            />
          </Button>
        )}
        {showError && (
          <Icon
            name="info-outline"
            size={24}
            className="text-red-500"
          />
        )}
      </View>
    </FieldLabel>
  );
};
