import React, { useState } from "react";

import { Button } from "../components/base/Button";
import { Form } from "../components/base/Form";
import { InputField } from "../components/base/InputField";
import { sessionActions } from "../redux/reducers/sessionSlice";
import { Text } from "../components/base/Text";
import { useAppDispatch, useAppSelector } from "../redux";
import { useNavigation } from "../hooks/useNavigation";
import { View } from "../components/base/View";

type FormData = {
  email: string;
};

export const ForgotPasswordPage = () => {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.session.isLoading);
  const [formData, setFormData] = useState<FormData>({ email: "" });
  const [resetPasswordSent, setResetPasswordSent] = useState<boolean>(false);

  const onChangeFormData = (newFormData: Record<string, unknown>) => {
    setFormData(newFormData as FormData);
  };

  const onClickContinue = async () => {
    if (resetPasswordSent) {
      navigation.goBack();
    } else {
      await dispatch(sessionActions.resetPassword(formData));

      setResetPasswordSent(true);
    }
  };

  const onClickBack = () => {
    navigation.goBack();
  };

  return (
    <View className="w-full h-full">
      <View className="w-full max-w-4xl mx-auto p-4 flex flex-col items-center justify-center min-h-screen">
        <View className="flex flex-col items-start w-full max-w-md bg-slate-800 bg-opacity-50 backdrop-blur-lg rounded-xl p-6 space-y-6 border border-purple-500/30">
          <Button
            type="basic"
            onClick={onClickBack}
            className="text-purple-300 hover:text-purple-200 !justify-start !shadow-none"
          >
            ← Back to Sign In
          </Button>

          {resetPasswordSent ? (
            <Text className="text-2xl text-gray-300 text-center">
              Check your email! We've sent you a link to reset your password if an account exists with that email address.
            </Text>
          ) : (
            <>
              <Text className="text-2xl text-gray-300 text-center">
                Enter your email below to reset your password.
              </Text>
              <Form
                data={formData}
                onChange={onChangeFormData}
              >
                <InputField
                  name="email"
                  placeholder="Email"
                  className="bg-slate-700 bg-opacity-50 text-white placeholder-gray-100 border-purple-500/30"
                />
              </Form>
            </>
          )}

          <Button
            onClick={onClickContinue}
            className="w-full bg-purple-600 text-white hover:bg-purple-700 text-xl py-4 border border-purple-400/30"
            isDisabled={!formData.email}
            isLoading={isLoading}
          >
            {resetPasswordSent ? "Back to Sign In" : "Send Reset Link"}
          </Button>
        </View>
      </View>
    </View>
  );
};
