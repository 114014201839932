import { createRestSlice } from "./restSlice";

export type Voice = {
  id: string;
  userId: string;
  name: string;
  sampleUrl: string;
  previewUrl: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};

const voiceSlice = createRestSlice<Voice>("voice", "/v1/voices");

export const voiceReducer = voiceSlice.reducer;
export const voiceActions = voiceSlice.actions;
