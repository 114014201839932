import clsx from "clsx";
import { useState } from "react";
import { FieldLabel } from "./FieldLabel";
import { View } from "./View";

type SwitchProps = {
  className?: string;
  label?: string;
  options: { label: string; value: string; icon?: React.ReactNode }[];
  value?: string;
  onChange?: (value: string) => void;
  labelClassName?: string;
};

export const Switch = ({
  className,
  label,
  options,
  value,
  onChange = () => {},
  labelClassName,
}: SwitchProps) => {
  const onChangeInternal = (newValue: string) => {
    onChange(newValue);
  };

  return (
    <FieldLabel
      label={label}
      className={labelClassName}
    >
      <View className={clsx(
        "flex rounded-md overflow-hidden border border-purple-500/30",
        className
      )}>
        {options.map((option, index) => (
          <button
            key={option.value}
            className={clsx(
              "flex w-full items-center justify-center flex-col py-2 px-4 text-base md:text-xl outline-none transition-colors border-r border-purple-500/30",
              value === option.value ? "bg-purple-600 text-white" : "bg-slate-800 text-white hover:bg-slate-700",
              index === options.length - 1 && "border-r-0",
            )}
            onClick={() => onChangeInternal(option.value)}
          >
            {option.icon}
            {option.label}
          </button>
        ))}
      </View>
    </FieldLabel>
  );
};
