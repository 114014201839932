import clsx from "clsx";

import { View } from "../components/base/View";
import { Text } from "../components/base/Text";
import { Button } from "../components/base/Button";
import { Icon } from "../components/base/Icon";
import { useEffect, useRef, useState } from "react";
import { formatTime } from "../utils/strings";

type AudioPlayerProps = {
  audioUrl?: string;
  className?: string;
}

export const AudioPlayer = ({
  audioUrl,
  className,
}: AudioPlayerProps) => {
  const audioRef = useRef<HTMLAudioElement>();
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isAudioLoaded, setIsAudioLoaded] = useState(false);

  const onClickPlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const onTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const onLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
      setIsAudioLoaded(true);
    }
  };

  const onClickProgress = (e: React.MouseEvent<HTMLDivElement>) => {
    if (audioRef.current) {
      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const percentage = x / rect.width;
      const newTime = percentage * duration;
      audioRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const onClickDownload = () => {
    if (audioUrl) {
      const link = document.createElement('a');
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      link.download = `audio-${timestamp}.wav`;
      link.href = audioUrl;
      link.click();
      link.remove();
    }
  };

  useEffect(() => {
    if (audioUrl) {
      audioRef.current = new Audio(audioUrl);
      audioRef.current.addEventListener('timeupdate', onTimeUpdate);
      audioRef.current.addEventListener('loadedmetadata', onLoadedMetadata);
      audioRef.current.addEventListener('ended', () => setIsPlaying(false));
      audioRef.current.addEventListener('pause', () => setIsPlaying(false));
      audioRef.current.addEventListener('error', () => setIsPlaying(false));
    } else {
      setIsAudioLoaded(false);
    }
    
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('timeupdate', onTimeUpdate);
        audioRef.current.removeEventListener('loadedmetadata', onLoadedMetadata);
      }
    };
  }, [audioUrl]);

  return (
    <View 
      className={clsx(
        "flex items-center justify-center gap-4 bg-slate-800 bg-opacity-50 backdrop-blur-lg rounded-xl px-2 py-1 border border-purple-500/30 w-full md:w-2/3 transition-all duration-300",
        isAudioLoaded ? "translate-x-0 opacity-100" : "-translate-x-full opacity-0"
      )}
    >
      <Button
        onClick={onClickPlay}
        type="basic" 
        className="!w-8 !h-8 !shadow-none hover:bg-purple-500/20 !rounded-full"
      >
        <Icon
          name={isPlaying ? "pause" : "play-arrow"}
          size={24}
          className="text-purple-400"
        />
      </Button>
      <View className="flex-1 pt-2">
        <div
          className="w-full h-2 bg-purple-500/20 rounded-full overflow-hidden cursor-pointer"
          onClick={onClickProgress}
        >
          <View 
            className="h-full bg-purple-500 rounded-full" 
            style={{ width: `${(currentTime / duration) * 100}%` }}
          />
        </div>
        <View className="flex justify-between mt-1 text-sm text-gray-400">
          <Text>{formatTime(currentTime)}</Text>
          <Text>{formatTime(duration)}</Text>
        </View>
      </View>
      <Button
        onClick={onClickDownload}
        type="basic"
        className="!w-8 !h-8 !shadow-none hover:bg-purple-500/20 !rounded-full"
      >
        <Icon
          name="download"
          size={18}
          className="text-purple-400"
        />
      </Button>
    </View>
  );
};
