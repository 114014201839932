import { useDebouncedCallback } from "use-debounce";
import React, { useState } from "react";

import { Button } from "../components/base/Button";
import { ErrorMessage } from "../components/shared/ErrorMessage";
import { Form } from "../components/base/Form";
import { InputField } from "../components/base/InputField";
import { openUrl } from "../utils/linking";
import { ProgressBar } from "../components/shared/ProgressBar";
import { sessionActions } from "../redux/reducers/sessionSlice";
import { Text } from "../components/base/Text";
import { useApiRequest } from "../hooks/useApiRequest";
import { useAppDispatch } from "../redux";
import { useNavigation } from "../hooks/useNavigation";
import { View } from "../components/base/View";
import { encodeState, parseState } from "../utils/parse";
import { useSearchParam } from "../hooks/useSearchParam";
import { CheckboxField } from "../components/base/CheckboxField";

type FormData = {
  email?: string;
  password?: string;
  confirmPassword?: string;
  name?: string;
};

export const SignUpPage = () => {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<FormData>({});
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const state = parseState<{ id: string }>(useSearchParam("state"));
  const passwordStrengthRequest = useApiRequest<{ strength: string; score: number }>();
  const createAccountApiRequest = useApiRequest();

  const onChangePassword = useDebouncedCallback((password) => {
    passwordStrengthRequest.fetch("/v1/auth/strength", {
      method: "POST",
      body: {
        password,
      },
    });
  }, 300);

  const onChangeFormData = (newFormData: FormData) => {
    setFormData(newFormData);

    if (!passwordStrengthRequest.isLoading && newFormData.password) {
      onChangePassword(newFormData.password);
    }

    if (createAccountApiRequest.error) {
      passwordStrengthRequest.clear();
      createAccountApiRequest.clear();
    }
  };

  const onClickContinue = async () => {
    const { error } = await createAccountApiRequest.fetch("/v1/auth/signup", {
      method: "POST",
      body: {
        email: formData.email,
        password: formData.password,
        name: formData.name,
      },
    });

    if (!error) {
      await dispatch(sessionActions.signIn({ email: formData.email, password: formData.password }));
      
      navigation.navigate("/checkout", {
        state: encodeState({
          id: state?.id || "",
        }),
      });
    }
  };

  return (
    <View className="w-full h-full">
      <View className="w-full max-w-4xl mx-auto p-4 flex flex-col items-center justify-center min-h-screen">
        <Text className="text-6xl font-bold text-white text-center mb-2">Prim Voices</Text>
        <Text className="text-xl text-gray-300 text-center mb-8 opacity-80">
          Where every story finds its voice.
        </Text>

        <View className="w-full max-w-md bg-slate-800 bg-opacity-50 backdrop-blur-lg rounded-xl p-6 shadow-xl mb-8 border border-purple-500/30">
          <Form
            className="space-y-6"
            data={formData}
            onChange={onChangeFormData}
          >
            <InputField
              name="name"
              placeholder="Name"
              className="bg-slate-700 bg-opacity-50 text-white placeholder-gray-100 border-purple-500/30"
            />
            <InputField
              name="email"
              placeholder="Email"
              className="bg-slate-700 bg-opacity-50 text-white placeholder-gray-100 border-purple-500/30"
            />
            <InputField
              name="password"
              placeholder="Password"
              type="password"
              className="bg-slate-700 bg-opacity-50 text-white placeholder-gray-100 border-purple-500/30"
            />
            <InputField
              name="confirmPassword"
              placeholder="Confirm Password"
              type="password"
              className="bg-slate-700 bg-opacity-50 text-white placeholder-gray-100 border-purple-500/30"
            />
          </Form>

          <View className="flex flex-col items-start justify-start mt-4">
            {!passwordStrengthRequest.data?.strength || (formData.password?.length || 0) < 10 ? (
              <Text className="text-gray-300 opacity-80">Password must be at least 10 characters long and include at least one number or one symbol.</Text>
            ) : createAccountApiRequest.error ? (
              <ErrorMessage text={createAccountApiRequest.error?.message} />
            ) : passwordStrengthRequest.data ? (
              <>
                <ProgressBar
                  className="w-full mb-2"
                  color={passwordStrengthRequest.data?.strength === "weak" ? "red" : passwordStrengthRequest.data?.strength === "medium" ? "yellow" : "green"}
                  progress={(passwordStrengthRequest.data?.score || 0) * 25}
                />
                <Text className="text-gray-300">
                  {passwordStrengthRequest.data.strength === "strong" ? "Strong Password" : "Please Strengthen Password"}
                </Text>
                <Text className="text-rose-300">{formData.confirmPassword && formData.password !== formData.confirmPassword ? "Passwords don't match" : " "}</Text>
              </>
            ) : null}
          </View>
        </View>

        <View className="w-full max-w-md space-y-4">
          <View className="flex flex-row items-center gap-2 ml-4 mb-8">
            <CheckboxField
              value={agreedToTerms}
              onChange={(newValue) => setAgreedToTerms(newValue)}
              className="!w-6 !h-6 rounded border-2 border-purple-500/50 bg-transparent checked:bg-purple-600 checked:border-purple-600 focus:ring-0 focus:ring-offset-0 focus:border-purple-500/50 cursor-pointer"
              labelClassName="!w-6 !h-6"
            />
            <View className="flex flex-row flex-wrap items-center gap-1">
              <Text className="text-sm text-gray-300">By creating an account you agree to our</Text>
              <Button type="basic" className="!shadow-none !w-auto" onClick={() => openUrl("https://www.tunetribute.com/terms")}>
                <Text className="text-sm text-purple-300 underline">Terms of Use</Text>
              </Button>
              <Text className="text-sm text-gray-300">and</Text>
              <Button type="basic" className="!shadow-none !w-auto" onClick={() => openUrl("https://www.tunetribute.com/privacy")}>
                <Text className="text-sm text-purple-300 underline">Privacy Policy</Text>
              </Button>
            </View>
          </View>

          <Button
            onClick={onClickContinue}
            className="w-full bg-purple-600 text-white hover:bg-purple-700 text-xl py-4 border border-purple-400/30"
            isDisabled={
              !formData.email ||
              !formData.password ||
              !formData.confirmPassword ||
              formData.password !== formData.confirmPassword ||
              passwordStrengthRequest.data?.strength !== "strong" ||
              !agreedToTerms
            }
          >
            Create Account
          </Button>
        </View>
      </View>
    </View>
  );
};
