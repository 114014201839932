let apiUrl = "";

switch (window.origin) {
  case "http://localhost:3000":
    apiUrl = "http://localhost:8080";
    break;
  case "http://local.primvoices.com:3000":
    apiUrl = "https://labs.primstories.com";
    break;
  case "https://app.primvoices.com":
    apiUrl = "https://api.primvoices.com";
    break;
  default:
    apiUrl = window.origin.replace("3000", "8080");
    break;
}

export const API_URL = apiUrl;
export const WS_URL = `${apiUrl.replace("http", "ws")}/ws`;
export const STRIPE_PUBLISHABLE_KEY = "pk_test_51PgpsAKxRAxzz8mLFvOqk2t6ycadJk63nCztY7LYCz18P80O7THnvYN3gABT9pWfaaKBu3AcuQ2SB6HHXeVFICIr006KuTqjoG";
