import { makeRequest } from "../../utils/request";
import { RestList } from "../../components/shared/RestList";
import { User } from "../../redux/reducers/userSlice";

export const AdminUsersPage = () => {
  const search = async (query: string) => {
    try {
      const { data } = await makeRequest<{ data: User[] }>(`/v1/users/search?q=${query}`);

      return data.map((user) => user.id);
    } catch (error) {
      console.error("Error searching users:", error);
    }

    return [];
  };

  return (
    <RestList
      className="w-full h-full"
      entityName="user"
      search={search}
      fields={[
        {
          name: "id",
          label: "ID",
          readOnly: true,
        },
        {
          name: "name",
          label: "Name",
        },
        {
          name: "email",
          label: "Email",
        },
        {
          name: "isAdmin",
          label: "Admin",
          type: "checkbox",
        },
      ]}
    />
  );
};
